.prev-block {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 15vw;

    p {
        font-size: rem(19px);
        line-height: 1.6;
    }

    &__image {

        position: absolute;
        width: 30%;
        left: 0;
        height: 24vw;

        &>img, iframe {
            object-fit: cover;
            position: absolute;
            bottom: 54%;
            left: -17.2vw;
            width: 36vw;
            max-width: unset;
            height: 100%;
            max-height: 100%;
        }
    }

    &__text {
        display: flex;
        background-color: $tr-bg;
        padding: 4vw 3vw 8vw 4vw;
        justify-content: space-between;

        &>div:last-of-type {
            width: 59%;
            min-width: 370px;
        }
        // position: relative;
        // padding: 8%;
        // padding-left: 30%;

        &>div {


            &>h3 {
                position: absolute;
                top: - rem(25px);
                font-size: rem(40px);
                font-weight: 500;
            }
        }

        &>a {
            // position: absolute;
            // bottom: 0;
            // left: 0;
        }

    }

    .transbutton {
        bottom: 0;
        width: 35%;
        left: 0;
        position: absolute;
    }
    
    &__buttons {
        bottom: 0;
        width: 30%;
        left: 0;
        position: absolute;
    }

    &::after {
        height: 10vw;
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #08151F 100%);
    }
    
    @include mq($until: xxl) {
        &__text {
            padding: 4vw 3vw 5vw 4vw;
        }

        &__image > img, iframe {
            bottom: 40%;
            // height: 95%;
        }
    }

    @include mq($until: xl) {
        margin-top: 100px;

        &__image > img, iframe {
            width: 42vw;
            left: -19.7vw;
        }
    }

    @include mq($until: lg) {

        &__text {


            &>div {

                &:last-of-type {
                    min-width: 37vw;
                }
            }
        }

        &__image {
            height: 26vw;
        }
        &__image > img, iframe {
            width: 46vw;
            left: -22.7vw;
            bottom: 25%;
        }
    }    

    @include mq($until: md) {

        &__text {
            padding: 5vw 3vw 70px 3vw;
            &>div {

                &:last-of-type {
                    min-width: 100%;
                }
            }
        }
        &__image > img, iframe {
            display: none;
        }

        .transbutton {
            width: 200px;
        }
    }    

    @include mq($until: xs) {

        &::after {
            height: 20vw;
        }
        padding-bottom: 100px;

        &__text {
            padding: 9vw 3vw 70px 3vw;
            
            p {
                font-size: rem(16px);
            }
            &> div {

                &> h3 {
                    top: -7vw;
                    font-size: 9vw;
                }
            }   
        }
    }        
    
}


.subsite {
    .prev-block {
        margin-bottom: 0;

        &__image {
            bottom: 0;

            img {
                height: 100%;
                bottom: 20%;
                left: -18.2vw;
            }
        }
    }
}

.fr-video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 55vw;
}    