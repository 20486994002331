.news-section {
    background-color: #ede4dd;
    padding-bottom: 100px;
}

.news-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    background-color: #ede4dd;

    &__title {
        background-color: #ede4dd;
        padding-bottom: 50px;
        h2 {
            color: black;
            margin-bottom: 0;
            text-align: center;
        }
    }
    &--promo{
        margin-bottom: 30px;
    }
    &__header-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__header{
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
    &__link{
        color: $color-main;
    }

    @include mq($until: xxl) {
        grid-gap: 1.5vw;
    }

    @include mq($until: xl) {
        grid-gap: 15px;
    }

    @include mq($until: lg) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2vw;
        row-gap: 40px;
    }

    @include mq($until: sm) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
    }
}