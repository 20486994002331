.subsite {
  padding-top: 110px;

  h2 {
    font-size: rem($header-size / 1.2);
    color: $color-main !important;
    text-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
  }

  h3 {
    color: $color-font-dark;
    font-size: rem($header-size / 1.6);
  }

  &__waterbending {
    background-image: url('../../images/bg-light.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    height: 200vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
  }

  &__top {
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 5;
  }

  &__content {
    min-height: 100vh;
    margin-top: -20vh;
  }

  h3 {
    color: #3C99CC;
  }

  h4, h5, h6, p, li, td, th, span, div {
    color: #000;
  }

  &__heading {
    z-index: 5;
    width: 37%;

    h1 {
      font-size: rem(65px);
      font-weight: 500;
      color: $color-main;
      text-shadow:  0 0 5px rgba(0, 0, 0, 0.3);
    }
  }

  &__slider {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  &~ .pre-footer .transbutton {
    background-color: #eae2ddf3;
  }

  @include mq($until: xxl) {

    &__heading {
      width: 47%;
    }
  }

  @include mq($until: xl) {

    &__waterbending, .movie  {
      background-position: 30%;
    }
  }

  @include mq($until: md) {

    &__content {
      margin-top: -20vh;
    }

    &__waterbending {
      height: 100vh;
    }

    &__waterbending, .movie  {
      background-position: center;
    }

    &__heading {
      width:100%;
    }

    & ~ .pre-footer {
      background-position: center;
      height: 75vh;
    }
  }

  @include mq($until: sm) {
    
    &__waterbending, .movie  {
      background-position: 40%;
    }
  }  
}

.news-page {
  &.pre-footer {
    min-height: 100vh;
    background-position: center 20vh;
    background-repeat: no-repeat;
    height: auto;
    align-items: flex-start;
  }
  .news__image {
    margin: 65px 0;
    img {
      width: 100%;
    }
  }
  .pre-footer__container {
    margin-top: 120px;
    width: 100%;
    margin-bottom: 0;
    a {
     margin: 0;
     width: auto;
    }
  }

  h1 {
    color: black;
    text-align: center;
    font-size: rem(50px);
  }
}