.offer {
  height: auto;
  margin-bottom: 12vw;
  z-index: 1;

  h3 {
    transition: all .3s;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    list-style: none;
    padding: 0 15px;

    li {
      padding-left: 0;
      &::before {
        content: none;
      }
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__technics {
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);

    &> p {
      display: flex;
      width: 150px;
      justify-content: space-between;
      font-weight: 300;
      margin-bottom: 10px;
    }

    &:hover {
      span, h3 {
        opacity: 1;
        color: $color-main;
      }
    }
  }

  .sprtr {
    width: 100%;
    height: 2px;
    background: rgb(32, 32, 32);
    margin: 20px 0;
  }

  &__price {
    display: flex;
    p {
      width: 100%;
    }
    span {
      opacity: .7;
      text-decoration: underline;
      transition: all .3s;
    }
  }

  @include mq($until: xl) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__technics {
      padding: 20px;
      &> p {
        width: 130px;
      }
    }
  }

  @include mq($until: md) {
      margin-bottom: 100px;
  }

  @include mq($until: xs) {
    margin-bottom: 50px;
    &__list {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
}

.offer-page {
  &.pre-footer {
    min-height: 100vh;
    background-position: center 20vh;
    background-repeat: no-repeat;
    height: auto;
    .sprtr {
      background: #b1b1b12e;
    }
    .offer__technics {
      background-color: #071520;
    }
  }
  .pre-footer__container {
    margin-top: 30vh;
    width: 100%;
    margin-bottom: 0;
  }

  .offer {
    margin-bottom: 0;
  }

  h1 {
    color: black;
    text-align: center;
    font-size: rem(50px);
  }
  .pre-footer__container span:nth-child(2) {
    margin-left: 10px;
  }
}