// =========================
// Modules and Variables
// =========================

/**
    mq() takes up to three optional parameters:

    $from: inclusive min-width boundary
    $until: exclusive max-width boundary
    $and: additional custom directives
 */
$mq-breakpoints: (
        xs:  576px,
        sm:  768px,
        md:  992px,
        lg: 1200px,
        xl: 1500px,
        xxl: 1920px
);

$tr-bg: rgba(255, 255, 255, 0.2);

$color-main: #f7941d;
$color-extra: #3c3c3c;
$color-font-light: #fff;
$color-font-dark: #3e3e3e;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 700;
$fw-black: 900;


// Font Family
$font-family: 'Jost', sans-serif;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color      : white;
$paragraph-size       : 17px;
$paragraph-line-height: 1.3em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : white;
$xtr-header-color      : $color-main;
$header-size       : 45px;
$header-line-height: 1.2em;
$header-margin     : $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color      : #505050;
$blockquote-size       : 23px;
$blockquote-line-height: 1.5em;
$blockquote-margin     : $paragraph-margin * 1.5;

// Links
$link-color: $color-main;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 600;
$border-color: #DADADA;