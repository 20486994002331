.news-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #F5F0EC;
    justify-content: flex-start;
    padding-bottom: 20px;
    &__header {
        font-size: 1.2rem;
        margin-top: 15px;
        margin-bottom: 15px!important;
    }

    &__more {
        margin-top: auto;
        padding-top: 15px;
        font-weight: 600;
        color: $color-main!important;
    }

    &__excerpt {
        margin-bottom: 40px!important;
    }

    h3, div {
        margin: 0 20px;
        color: black;
    }

    span {
        margin-left: 5px;
    }

    img {
        margin-bottom: 40px;
        width: 100%;
    }
}