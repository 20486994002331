.project-ind {
    display: grid;
    grid-template-columns: 28% 37% 35%;
    background-color: #08151f;
    background-image: url('../../images/top-wave2.png');
    background-position: center 3vw;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    &__center {
        grid-row: span 2;
        height: min-content;
    }

    &__text {
        position: absolute;
        top: 80%;
        z-index: 2;
        background: linear-gradient(180deg, #00000000 10%, rgba(255, 255, 255, 0.3) 100%);
        padding: 3vw 0 3vw 3vw;
        display: flex;
        border-radius: 5px;

        p {
            color: black;
        }

        &> div {
            width: 62%;
            padding-right: 3vw;
        }

        &> a {
            background-color: transparent;
            width: 38%;
            display: flex;
            place-items: flex-end;
            justify-content: center;
        }
    }

    &__heading {
        padding: 2vw 3vw;
    }

    &__right {
        z-index: 3;
    }

    @include mq($until: xl) {
        &__text {
    
            &> div {
                width: 55%;
            }

            &> a {
                width: 45%;
            }
        }
    }

    @include mq($until: lg) {
        &__text {
            &> div {
                width: 53%;
            }
            &> a {
                width: 48%;
            }
        }
    }

    @include mq($until: md) {
        grid-template-columns: 0% 55% 45%;
        background-position: -12vw 9vw;
        &__text {
            padding: 50px 0 50px 50px;
        }
        &__heading {
            padding: 0 50px;
        }
        &__left {
            // display: none;
        }
    }   

    @include mq($until: sm) {
        &__text {
            padding: 30px 0 30px 30px;
        }
        &__heading {
            padding: 0 30px;
        }
    }   

    @include mq($until: xs) {
        grid-template-columns: 0% 100% 0%;
        background-position: -50vw 9vw;
        background-size: 200% 100%;
        &__heading {
            padding: 0 30px;
        }
        &__text {
            flex-direction: column;
            padding: 15px 0 15px 15px;
            position: relative;
            top: 40px;
            margin-left: 15px;
            &> div {
                width: 100%;
            }

            &> a {
                width: 100%;
                height: 40px;
            }
        }
    }
}

.trans-bg {
    padding: 180px 0 90px 0;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 17.67%, $tr-bg 100%);


    &>div:nth-child(2) {
        margin-left: 10%;
    }

    .transbutton {
        width: 33%;
        right: 0;
        bottom: 0;
        position: absolute;
    }

    h2, span {
        font-size: 2.5vw;
    }

    @include mq($until: xl) {
        padding: 90px 0 60px 0;
    }

    @include mq($until: md) {
        padding: 110px 1vw 50px 1vw;

        &>div:nth-child(2) {
            margin-left: 0;
        }

        h2, span {
            font-size: 3.5vw;
        }

        .transbutton {
            width: 200px;
        }
    }

    @include mq($until: xs) {
        padding: 30px 1vw 60px 1vw;
        margin-top: 80px;
        background: $tr-bg;

        h2, span {
            font-size: 4.5vw;
        }
    }    
}

.icons-container {
    background-color:#ede4dd;
    text-align: center;
    padding-top: 6vw;

    .icons-block {

        padding-top: 2vw;
        padding-bottom: 6vw;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        span {
            color: black;
            font-size: .7vw;
            font-weight: 500;
        }
    
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1.2vw;
    
            img {
                width: 80%;
            }
        }

        @include mq($until: xl) {
            span {
                font-size: .8vw;
            }
        }
    
        @include mq($until: lg) {
            span {
                font-size: .9vw;
            }
        }
    
        @include mq($until: md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 1vw;

            
            span {
                font-size: 1.6vw;
            }

            &__item {
                img {
                    width: 60%;
                    margin-bottom: 10px;
                }
            }
        }

        @include mq($until: xs) {
            grid-template-columns: 1fr 1fr;
            gap: 1.5vw;
            row-gap: 3vw;

            
            span {
                font-size: 3vw;
            }
        }
    }

        @include mq($until: md) {
            
            h2 {
                font-size: 3.6vw;
            }
        }

}



.slogan {

    margin-top: 23vw;
    width: 50vw;
    margin-left: 5vw;
    z-index: 1;

    h2 {
        font-weight: 400;
        font-size: rem(45px);
        line-height: 1.2;
        text-shadow:  0 0 5px rgba(0, 0, 0, 0.418);

        span {
            font-size: inherit;
            display: block;
            line-height: inherit;
        }
        span:nth-child(1) {
            margin-left: 11%;
        }

        span:nth-child(2) {
            margin-left: 5%;
        }

        span:nth-child(3) {
            margin-left: 8%;
        }

    }

    @include mq($until: md) {
        margin-top: 33vw;
        margin-left: 0;
    }

    @include mq($until: sm) {
        width: 100%;
        margin-top: 10vw;

        h2 {
            font-size: 6vw;
        }

        span {
            display: inline!important;
            margin: 0!important;
        }
    }
}

.about {
    z-index: 1;
    padding-top: 20vw;
    padding-bottom: 10vw;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 20%, #ECE3DC 100%);


    &__inner {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, rgba(0,212,255,0) 25%, $tr-bg 25%);
        border-radius: 5px;
        img {
            width: 100%;
        }

        div:first-of-type {
            padding: 4vw 0;
        }

    }

    &__text {
        padding: 5vw;
        display: flex;
        align-self: center;
    }

    p {
        color: black;
        line-height: 1.8;
        font-size: rem(19px);
    }

    @include mq($until: md) {

        &__inner {

            margin-top: 10%;
            flex-direction: column;
            background: $tr-bg;
            margin-bottom: 10%;

            div:first-of-type {
                padding: 0 0;
            }

            img {
                width: 75%;
                height: 30vw;
                margin: auto;
                display: block;
                object-fit: cover;
                margin-top: -10%;
            }
        }


    }

    @include mq($until: sm) {

        &__inner {
            margin-bottom: 0;
        }
    }
    

    @include mq($until: xs) {

        &__inner {


            img {
                height: 50vw;
                width: 100%;
            }
        }

        p {
            font-size: rem(16px);
        }
    }
}

.pre-footer {
    width: 100%;
    height: 52vw;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;

    &__container {
        margin-bottom: 7vw;
        width: 65vw; 


        h3 {
            font-weight: 400;

            span {
                font-size: inherit;
            }
        }

        span:nth-child(2) {
            margin-left: 30%;
        }

        span:nth-child(4) {
            margin-left: 5%;
        }

        span:nth-child(6) {
            margin-left: 25%;
        }

        a {
            margin: 40px auto;
            display: block;
            width: 300px;
        }
    }

    @include mq($until: lg) {

        &__container {
            margin-bottom: 0;
            width: 70vw;
        }
        h3 {
            font-size: rem(27px);
        }
    }

    @include mq($until: md) {

        &__container {
            width: 529px;
        }
        h3 {
            font-size: rem(25px);
        }
    }
    
    @include mq($until: sm) {
        height: 500px;
        background-position: center;

        h3 {
            font-size: rem(22px);
            margin-left: 19%;
        }

        &__container {
            width: 100%;
        }
    }

    @include mq($until: xs) {

        h3 {
            width: 85vw;
            margin: auto;
            font-size: 4vw;        
        }
    }    
}

.video2 {
    background-color: #ede4dd;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100vw;
    background-position: center -15vw;

    &::before {
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #B6C4D5 0%, rgba(255, 255, 255, 0) 100%);
        height: 10vw;
    }

    @include mq($until: lg) {
        height: 120vw;
        background-position: center -10vw;
    }

    @include mq($until: md) {
        height: 150vw;
        background-position: center -15vw;
    }

    @include mq($until: xs) {
        height: 200vw;
        background-position: 60% -40vw;
    }
}

#myVideo {
    position: absolute;
    min-width: 100%;
    min-height: 100vw;
    top: 90vh;
    display: block;
    z-index: -1;
}

