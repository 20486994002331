.header {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  min-height: 80px;
  padding-bottom: 25px;
  width: 100%;
  top: 0;
  background-color: #292a3ae5;
  transition: all .3s;
  z-index: 10;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    width: 250px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    width: 85%;
    justify-content: space-between;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: white;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:hover {
     color: $color-main;
    }
    &--active, &--open, &:active {
      color: $color-main;
    }
  }

  form {
    position: relative;
    margin-left: 35px;
  }

  @include mq($until: lg) {
    min-height: 75px;

    &__logo {
      width: 240px;
    }

    &__menuLink {
      font-size: rem(15px);
    }
    
  }
  @include mq($until: md) {

    form {
      position: absolute;
      right: 15px;
    }

    &__nav {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__logo {
      width: 200px;
    }

    &__menuList {
      align-items: flex-start;
    }

    &__menuLink {

      font-size: 4vw;
      &:hover, &--active, &--open, &:active {
        color: #161e2b;
      }
    }

    &__menuItem {
      margin-top: 20px;
    }

  }

  @include mq($until: sm) {
    &__menuLink {
      
      font-size: 5vw;
    }
  }

  @include mq($until: xs) {
    &__menuLink {
      
      font-size: 6vw;
    }
  }
  
}

.form-control {
  height: 26px;
  padding: 3px 15px 3px 8px;
  font-size: rem(12px);
  font-weight: 600;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid white;
  border-radius: 0.2rem;
  appearance: none;
  transition: all .2s;
  &:focus {
    color: #495057;
    background-color: rgba(255, 255, 255, 0.281);
    border-color: white; 
    outline: 0;
    box-shadow: none; 
  }
  option {
    font-size: rem(12px);
    background-color: rgba(255, 255, 255, 0.281);
  }
}

.tri {
  background-image: url('../../images/tri.png');
  display: block;
  width: 8px;
  height: 6px;
  position: absolute;
  background-size: cover;
  top: 40%;
  right: 7px;
  pointer-events: none;
  
}