.project {
  position: relative;

  h1 {
    font-size: rem(60px);
    margin-top: -1.5vw;
    z-index: 1;
    position: relative;
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &__short {
    margin-top: 70px;
  }

  &__slider {
    height: 100vh;
    width: 100%;
		position: relative;

		& *:not(.project__slider-arrow) {
			width: 100%;
			height: 100%;
		}

    img {
      object-fit: cover;
      object-position: center;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      background-image: url('../../images/fala2.svg');
      width: 100%;
      height: 20vw;
      z-index: 1;
      background-size: 100% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
    }
	}

  &__plan {
    margin-top: rem(50px);
    img {
      width: 100%;
    }
  }

	&__slider-arrow {
		position: absolute;
		bottom: 5vw;
		z-index: 2;
		border: none;
		width: 60px;
		height: 60px;
		background-color: #8686868c;
		border-radius: 50%;
		transition: all .4s;
		background-size: 30%;
		background-repeat: no-repeat;

		&_next {
			left: calc(80% + 60px);
			background-image: url('../../images/next.png');
			background-position: 56% center;
		}

		&_prev {
			left: calc(75% + 60px);
			background-image: url('../../images/prev.png');
			background-position: 44% center;
		}

		&:hover {
			background-color: $color-main;
		}
	}

  &__cta {
    display: flex;
    width: 100%;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(50px);
    button {
      // max-width: 600px;
      border-radius: 0;

      &:hover {
        background-color: $color-extra;
        border-color: $color-extra;
      }
    }

  }

  &__description {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  @include mq($until: xl) {
    &__slider-arrow {
      bottom: 4vw;
      width: 50px;
      height: 50px;
    }
  }

  @include mq($until: lg) {
    &__slider-arrow {
      bottom: 4vw;

      &_next {
        left: calc(84% + 60px);
      }
  
      &_prev {
        left: calc(75% + 60px);
      }
    }
  }

  @include mq($until: md) {
    h1 {
      margin-top: 0;
    }
    &__description {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    &__slider {
      height: 60vh;
      min-height: 600px;

      &::after {
        background-size: auto 100%;
      }
    }

    &__slider-arrow {
      bottom: 3vw;
      width: 45px;
      height: 45px;

      &_next {
        left: calc(100% - 45px - 50px);
      }
  
      &_prev {
        left: calc(100% - 45px - 130px);
      }
    }
  }

  @include mq($until: sm) {
    &__description {
      margin-top: 20px;
    }
    &__cta {
      margin-top: 50px;
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    &__slider {
      height: 65vh;
      min-height: 450px;
    }

    &__slider-arrow {
      bottom: 2vw;

      &_next {
        left: calc(100% - 45px - 30px);
      }
  
      &_prev {
        left: calc(100% - 45px - 100px);
      }
    }
  }

  @include mq($until: xs) {

    &__cta {
      flex-direction: column;
      margin-top: 20px;
      button {
        width: 100%;
        margin-top: 20px;
      }
  
      p {
        padding-right: 0;
      }
    }

    &__slider {
      height: 70vh;
      min-height: 400px;

      &::after {
        height: 100px;
      }
    }

    &__slider-arrow {

      &_next {
        left: calc(100% - 45px - 15px);
      }
  
      &_prev {
        left: calc(100% - 45px - 80px);
      }
    }
  }
}