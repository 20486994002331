footer {
  z-index: 5;
}

.footer {
  background: #071520;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;

  &__social {
    svg {
      width: 30px;
      fill: rgba(255, 255, 255, 0.288);
      margin-left: 20px;
      
      &:hover {
        fill: white;
      }
    }
  }

  &> div {
    padding: 0;
  }

  &__contact {
    margin-top: 1.9vw;
    display: flex;
    justify-content: space-between;

    &> div:not(.footer__social){
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0;
    }

    &_name {

      p {
        margin-top: 30px;
        font-size: 1.37vw;
      }
    }
  }

  &__logo {
    width: 90%;

    img {
      max-width: unset;
      width: 15vw;
    }
  }

  a {
    color: white;
  }

  &__menuList {
    display: flex;
    align-items: center;
    margin-top: 1.2vw;
    justify-content: space-between;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0;
    margin: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: white;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: #61aad9;
    }
    &--active-ft {
      color: #61aad9;
    }
  }

  @include mq($until: md) {

    &> div {
      &:last-of-type {
        display: flex;
        justify-content: space-between;
      }
    }

    &__menuList {
      align-items: flex-start;
      flex-direction: column;
      margin-left: 15%; 

      li {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__contact {
      flex-direction: column;
      margin-top: 15px;
    }

    &__social {
      svg {
        &:first-of-type {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }

  @include mq($until: sm) {

    &__logo img {
      width: 25vw;
    }

    &__contact_name {
      p {
        font-size: 2.3vw;
        margin-top: 15px;
      }
    }
  }

  @include mq($until: xs) {
    flex-direction: column;

    &__menuList {
      margin-top: 15px;
      margin-left: 0; 
      margin-bottom: 6px;
    }

    &__logo img {
      width: 35vw;
    }

    &__contact_name {
      p {
        font-size: 3.3vw;
      }
    }
  }

}

.footer-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  &__realization{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      transition: color .3s ease-in-out;
    }
    &:hover span{
      color: $color-main;
    }
    img{
      margin-left: 15px;
    }
  }

  &__copyright, a, span {
    color: #828282
  }
}