.top {
  height: 106vh;
  background-size: cover;
  align-items: flex-end;
  display: flex;
  position: relative;
  background-position: center bottom;
  background-color: #292a3a;

  h1 {
    font-size: 10vw;
    font-weight: 400;
    color: white;
    line-height: .8;
  }

  h2 {
    margin-bottom: 30px;
  }

  h1, h2 {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }

  p {
    margin-left: 30%;
    width: 800px;
    font-size: rem(19px);
  }

  &__slider {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    * {
      height: 100%;
      width: 100%;
    }
  } 

  &__slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__heading {
    position: absolute;
    bottom: 10vw;
    width: 100%;
  }

  &:before {
    content: '';
    background-image: url('../../images/top-water-bg.jpg');
    width: 100%;
    height: 100vw;
    z-index: -1;
    position: absolute;
    top: 70%;
  }

  &:after {
    position: absolute;
    content: '';
    background-image: url(../../images/fala.svg);
    width: 100%;
    height: 14vw;
    display: block;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  &__wrapper {
    width: 100%;
    position: absolute;
    height: 100vh;
    top: 0;
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 5.5vw;
    z-index: 10;
    background-color: #2A313B;
    border-color: #2A313B;
    color: $color-main;
    font-weight: 600;
  }

  @include mq($until: xl) {
    &__button {
      right: 70px;
    }
  }

  @include mq($until: lg) {

    h1, p {
    }

    h2 {
      font-size: rem(22px);
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      font-size: rem(16px);
      width: 600px;
    }

    // &__heading {
    //   bottom: -120px;
    // }

  }

  @include mq($until: md) {
    height: 63vh;

    &__button {
      right: 50px;
      bottom: 2vw;
    }

    &__slider {
      height: 60vh;
    }
    h1 {
      font-size: 13vw;
    }

    &__heading {
      bottom: 180px;
    }

    &__wrapper {
      height: 77vh;
    }

    p {
      width: 69%;
    }

    &::before {
      height: 130vw;
    }

    .top h1, .top h2 {
      text-shadow: 0 0 7px rgba(0, 0, 0, 0.9);
    }
  }

  @include mq($until: sm) {
    &__button {
      margin: 0 auto;
      bottom: -20px;
    }
  }

  @include mq($until: xs) {
    &__button {
      margin: 0 auto;
      right: 15px;
    }
    h1 {
      font-size: 15vw;
    }
    h1, p {
      width: 90%;
    }
  }
}

.gradient {
  height: 15vw;
  width: 100%;
  background: linear-gradient(180deg, #050C16 0%, rgba(5, 12, 22, 0) 100%);
  z-index: 1;
  margin-bottom: -15vw;
}