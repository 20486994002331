.movie { 
  display: none;
}

@include mq($from: md) {
  .movie {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    background-size: cover;
    opacity: 0.8;
    -webkit-transition: opacity 3s linear;
    -o-transition: opacity 3s linear;
    transition: opacity 3s linear;
    transition-property: opacity;
    transition-duration: 3s;
    transition-timing-function: linear;
    transition-delay: initial;
    transition: all 3s;
    pointer-events: none;
    background-repeat: no-repeat;
  }
  
  #house {
    z-index: 1;
    opacity: 1;
    transition: none;
  }
  
  #wave {
    z-index: 1;
    opacity: .7;
  }
  
  #fog1 {
    animation: MoveLeft 10s linear infinite;
  }
  
  #fog2 {
    animation: MoveRight 7s linear infinite;
  }
  
  #waves {
    animation: MoveWave 5s linear infinite;
  }
  
  @keyframes MoveLeft {
    0%, 100% {
      transform: translateX(0);
      opacity: 0;
    }
    10% {
        opacity: .4;
      }
    60% {
      opacity: .7;
    }
    95% {
        transform: translateX(-30vw);
        opacity: 0;
    }
  }
  
  @keyframes MoveRight {
  0%, 100% {
    transform: translateX(0);
    opacity: 0;
  }
  40% {
    opacity: .6;
  }
  95% {
    transform: translateX(15vw);
    opacity: 0;
    }
  }
  
  @keyframes MoveWave {
    0%, 100% {
        transform: scale(1);
        opacity: 0;
    }
    1% {
        opacity: .7;
    }     
    50% {
        opacity: .9;
    }    
    80% {
        opacity: .7;
    }    
    99% {
        transform: scale(1.3);
        opacity: 0;
    }
  }
}